const firebase = require("firebase/app");
// Required for side-effects
require("firebase/firestore");


//This is production config

const firebaseConfig = {
  apiKey: "AIzaSyBnBQ5qmbDw_3rlOiT_3JM5DYMe85K9HgQ",
  authDomain: "cryptoecons.firebaseapp.com",
  databaseURL: "https://cryptoecons.firebaseio.com",
  projectId: "cryptoecons",
  storageBucket: "cryptoecons.appspot.com",
  messagingSenderId: "487397826246",
  appId: "1:487397826246:web:3dab5a5f8db6b3971341c7"
};

//this is test config. Yeah the names are confusing but deal with it

// const firebaseConfig = {
//   apiKey: "AIzaSyAtuYQGG9Jf1Jk87fyJKzDQGizDvkuqIKE",
//   authDomain: "cryptoecos-prod.firebaseapp.com",
//   databaseURL: "https://cryptoecos-prod.firebaseio.com",
//   projectId: "cryptoecos-prod",
//   storageBucket: "cryptoecos-prod.appspot.com",
//   messagingSenderId: "102566268993",
//   appId: "1:102566268993:web:cdc921a4199edc511a76c0"
// };


let fire = firebase.initializeApp(firebaseConfig);

export default fire;