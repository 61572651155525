import React, { Component } from 'react';
import styles from '../styles/LandingPage.module.css'
import WaitingList from "./WaitingList";
import Charts from "./Charts";
import config from '../config'
import Modal from 'react-modal';
import Loader from "react-loader-spinner";
import background from '../assets/sav/background.svg'
import database from '../services/database'
import ScrollAnimation from 'react-animate-on-scroll';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import axios from "axios";


const queryString = require('query-string-es5');

let firestore = database.firestore();

const THEME_LIGHT = "#c49a2e";
const THEME_DARK = "#cfb46a";

const SHARE_STRING = 'Buy, Sell and Trade Cryptocurrencies for free. Sign up for priority access now using my referral link.'

const SURVEY_Q = 'Which of these services are you most excited to use?';
const OPTIONS = ['Free Digital Assets Trading', 'Free Global Money Transfer', 'Micro Lending', 'Free Digital Assets Education', 'Intelligent Personal Investment AI'];

const videoModalStyle = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.44)'
    },
    content : {
        width: '90%',
        top                : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const contactModalStyle = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.44)'
    },
    content : {
        width: (window.innerWidth < 900 ? '90%': '75%'),
        maxHeight: (window.innerWidth < 900 ? 600 : 800),
        top                : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        paddingTop: 20,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        zIndex: 100
    }
};

const ABOUT_TXT1 = "Eve is an intelligent Digital Assets hub where you can buy, sell or trade Cryptocurrencies for free, with no effort from your smartphone or computer."
const ABOUT_TXT2 = "It also provides a set of analysis tools that integrates real-time market data, news, in-depth research and relevant crowd-sourced information about Cryptocurrencies and Digital Assets."
const ABOUT_TXT3 = "Multiply your wealth by earning an interest on the Money 2.0 sitting idle in your wallet, through our global micro lending platform."
const ABOUT_TXT4 = "If you have no idea what Digital Assets are, learn about them and the industry with the help of our educational resources."

const SHARE_TXT = ABOUT_TXT1 + ' ' + ABOUT_TXT3
const AI_TXT1 = 'Our proprietary AI assistant Satoshi will be your personal guide on the platform.'
const AI_TXT2 = 'She understands your investment persona and risk appetite over time and will help navigate your financial journey smoothly.'
const AI_TXT3 = 'Ask her for new information from the crypto industry or schedule a trade to be executed sometime in the future or request her to remind you when prices fluctuate or just use her help to navigate the app easily.'

const WHY_EVE_TEXTS = [
    {
        header: 'Easy Access',
        image: require('../assets/sav/EasyAccess.png'),
        text: 'Access hundreds of trading pairs with ease and buy, sell, trade and lend with one click. Fastest on-ramp and off-ramp in the industry to exchange FIAT to Digital Assets and vice versa.'
    },
    {
        header: 'Micro Lending',
        image: require('../assets/sav/Microlending.png'),
        text: 'Earn interest as high as 30% per annum through our peer-to-peer micro lending platform. You choose who to lend it to, for how long and at what interest rate.'
    },
    {
        header: 'Security And Reliability',
        image: require('../assets/sav/Security_copy.png'),
        text: 'We ensure you can sleep peacefully at night by storing and protecting all your data in compliance with your state regulations. Store your digital assets in our highly secure multi-factor enabled hot wallets or your own uber-safe cold wallets.'
    },
    {
        header: 'Education',
        image: require('../assets/sav/Education.png'),
        text: 'Learn from trading in our virtual investment platform or take one of our curate courses to dive deep into a particular digital assets topic you\'d  like to learn more about.'
    },
    {
        header: 'Forums and News',
        image: require('../assets/sav/news.png'),
        text: 'Get away from the noise of the traditional/social media and connect with other digital assets enthusiasts, through our dedicated and user created social forums.'
    },

]

class LandingPage extends Component {

    constructor() {
        super();
        this.state = {
            userDocId: null,
            coins: null,
            modalOpen: false,
            contactUs: false,
            modalHeader: null,
            modalVideoId: null,
            modalLoader: false,
            contactName: '',
            contactEmail: '',
            contactEmailError: false,
            contactMessage: '',
            userSignedUp: false,
            userEmail: '',
            userPosition: null,
            userLink: null,
            prevUser:null,
            alreadyShared: false,
            userReferrals: 0,
            showSurvey: false,
            surveyAnswers: {},
            surveyLoading: false,
            surveySubmitted: false,
            totalUsersSignedUp: null,
            luckyUser: false,
            luckyUserEmail: '',
            luckyUserEmailError: false
        }
        this.textInput = null;
        this.aboutRef = React.createRef();
        this.whyCryptoRef = React.createRef();
        this.whyEveRef = React.createRef();
        this.aiRef = React.createRef();
        this.userLocation = null;

        this.shareIconsSize = (window.innerWidth > 900) ? 60 : 40;
        this.onSignupPressed = this.onSignupPressed.bind(this);
        this.getTotalCountOfUsers = this.getTotalCountOfUsers.bind(this);
        this.onSurveyAnswerClicked = this.onSurveyAnswerClicked.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showModalLoader = this.showModalLoader.bind(this);
        this.onContactSubmitPressed = this.onContactSubmitPressed.bind(this);
        this.renderModalPostSignupSurvey = this.renderModalPostSignupSurvey.bind(this);
        this.renderModalQrUserSignup = this.renderModalQrUserSignup.bind(this);
    }

    componentDidMount() {
        firestore.collection('WLEmails').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let data = doc.data();
                    let email = data.email;
                    if(email && email.includes('ri'))
                        console.log(email)
                })
            })
        // this.getTotalCountOfUsers();
        // const searchString = this.props.location.search;
        // let params = queryString.parse(searchString);
        // if(params['inLink']) {
        //     let inboundLink = params['inLink'];
        //
        //     //if incoming from qr code scanner
        //     if(inboundLink === 'qr') {
        //         this.setState({
        //             luckyUser: true,
        //             modalOpen: true
        //         })
        //     }
        // }

    }

    onLinkClick() {
        console.log('link clicked', this.props.history)
        this.props.history.push('#commission')
    }

    renderCoinMarketCaps(coins) {
        var toReturn = [];

        coins.forEach(coin => {
            toReturn.push(this.renderCoin(coin))
        })

        return toReturn;
    }



    showModal(docId, email, position, url, prevUser, referrals, surveyAlreadySubmitted) {
        this.setState({
            userDocId: docId,
            userEmail: email,
            userPosition: position,
            userUrl: url,
            prevUser: prevUser,
            userReferrals: referrals,
            modalLoader: false,
            surveyAlreadySubmitted: surveyAlreadySubmitted
        })
    }

    //show modal and then show loader
    showModalLoader() {
        this.setState({
            modalOpen: true,
            userSignedUp: true,
            modalLoader: true,
            luckyUser: false,
            surveyAnswers: {}
        })
    }

    renderCoin(coin) {
        if(!coin)
            return;

        return(
            <div className={styles.indCoinWrapper} key={coin.id}>
                <p style={{fontSize: '14px', marginRight: '8px'}}>{coin.name}</p>
                <p style={{fontSize: '14px', marginRight: '2px'}}>${Math.round(coin.price * 1000) / 1000}</p>
                {
                    coin.percentChange > 0 ?
                        <p style={{color: 'green'}}>({coin.percentChange}%)</p>
                        :
                        <p style={{color: 'red'}}>({coin.percentChange}%)</p>
                }
            </div>
        )
    }

    onSignupPressed() {

        let temp = this.state.luckyUserEmail;
        let email;

        if(!temp || temp === '' || temp.length === 0 || !this.validateEmail(temp)) {
            this.setState({luckyUserEmailError: true})
            return;
        }

        email = temp.toLowerCase();

        this.showModalLoader();



        const self = this;
        firestore.collection('WLEmails')
            .where('email', '==', email)
            .get()
            .then(snapshot => {
                if(snapshot.size !== 0) {
                    //user has already signed up. show the waiting list position
                    snapshot.forEach(doc => {
                        const data = doc.data();
                        self.userId = doc.id;

                        let surveySubmitted = data.surveySubmitted || false;
                        let referralId = self.getUniqueLink(data.email)
                        let url = 'https://evex.app/?referral=' + referralId;
                        self.showModal(doc.id, data.email, data.position, url, true, data.referrals, surveySubmitted);
                        //if for soem reason the first the when the user signed up, there was no refrall id
                        if(!data.referral) {
                            self.updateReferralIdForUser(doc.id, referralId);
                        }
                    })
                } else {
                    let referralId = self.getUniqueLink(email);
                    let url = 'https://evex.app/?referral=' + referralId;
                    let currentUsersList = self.state.totalUsersSignedUp;
                    firestore.collection('WLEmails').add({
                        email: email,
                        position: currentUsersList + 1,
                        ogPosition: currentUsersList + 1,
                        referrals: 0,
                        referral: referralId,
                        createdAt: new Date().toISOString()
                    }).then((docRef) => {
                        self.showModal(docRef.id, email,currentUsersList + 1, url, false, 0, false);
                        self.userId = docRef.id;
                        self.sendEmail(email, currentUsersList, url);//user position -1 needs to be sent to email API because of the copy
                        self.getAndUpdateTotalCount();
                    }).catch((err) => {
                        console.log('something went wrong when signin up qr user', err)
                        self.setState({luckyUserEmail: '', userPosition: null, signedUp: false, modalLoader: false, modalOpen: false})
                    })
                }
            })
    }

    getAndUpdateTotalCount() {
        let countRef = firestore.collection("WLEmails").doc("count");

        firestore.runTransaction(function(transaction) {
            return transaction.get(countRef).then(function(countDoc) {
                if (!countDoc.exists) {
                    throw "Document does not exist!";
                }

                let newTotal = countDoc.data().total + 1;
                transaction.update(countRef, { total: newTotal });
                return newTotal;
            });
        }).then(function(newTotal) {
            console.log("added");
        }).catch(function(err) {
            // This will be an "population is too big" error.
            console.error(err);
        });
    }

    sendEmail(email, position, url) {

        const sendgridData = {
            email: email,
            position: position,
            url: url
        }

        let self = this;
        axios.post(" https://us-central1-cryptoecons.cloudfunctions.net/eve-api/api/email", sendgridData)
            .then(response => {
                if (response && response.status === 200) {
                    console.log('sent email to user through api',)
                } else {
                    self.sendEmailThroughEmailJs(email, position, url)
                    console.log('error when sending email through api because', response.status, response.statusText)
                }
            }).catch((err) => {
            self.sendEmailThroughEmailJs(email, position, url)
            console.log('error when sending email through api', err)
        })



    }

    sendEmailThroughEmailJs(email, position, url) {
        const headers = {
            'Content-Type': 'application/json'
        }

        const emailJsData = {
            service_id: 'default_service',
            template_id: 'template_o0qs8W9b',
            user_id: 'user_Mu9xOl6JnJ4uDsTq20siM',
            template_params: {
                position: position,
                to_email: email,
                url: url,
                reply_to: 'support@evex.app'
            }
        }


        axios.post("https://api.emailjs.com/api/v1.0/email/send", emailJsData, {headers: headers})
            .then(response => {
                if (response && response.status === 200) {
                    console.log('sent email to user through js',)
                } else {
                    console.log('error when sending email', response.status, response.statusText)
                }
            }).catch((err) => {
            console.log('error when sending email', err)
        })
    }

    getUniqueLink(email) {
        var hash = 0, i, chr;
        if (email.length === 0) return null;
        for (i = 0; i < email.length; i++) {
            chr   = email.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return hash;
    }

    getTotalCountOfUsers() {
        const self = this;
        firestore.collection("WLEmails").doc("count")
            .onSnapshot(function(doc) {
                let totalUsersSignedUp = doc.data().total;
                console.log('got newest data')
                self.setState({totalUsersSignedUp: totalUsersSignedUp})
            });
    }

    updateReferralIdForUser(id, referralId) {
        firestore.collection('WLEmails').doc(id).update({
            referral: referralId
        }).then(() => {
            console.log('updated user without the rererral before')
        }).catch((err) => {
            console.log('something happened when updating user referral position', err)
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onContactEmailChanged(e) {
       this.setState({
           contactEmail: e.target.value,
           contactEmailError: false
       })
    }

    onContactSubmitPressed() {
        let email = this.state.contactEmail;
        if(!email || email.length === 0 || !this.validateEmail(email)) {
            this.setState({
                contactEmailError: true
            })
            return;
        }
        const self = this;

        firestore.collection('contactUs').add({
            email: email,
            name: self.state.contactName,
            message: self.state.contactMessage || '',
            createdAt: new Date().toISOString()
        }).then((docRef) => {
            console.log('submitted user contact form')
            self.setState({
                contactUs: false,
                modalOpen: false
            })
            window.alert("We have received your message.");
        }).catch(err => {
            console.log('error when adding contact user to db', err)
            self.setState({
                contactUs: false,
                modalOpen: false
            })
        });
    }

    renderDiffLayout(value, index) {
        return (
            <div className={styles.valueRow2} id={value.id}>
                <div className={styles.valueRow2Inner}>

                    <img
                        src={value.image}
                        style={{width: 60, height: 60, borderRadius: '50%', position: 'relative', top: 10}}>
                    </img>


                    <h2 className={styles.headerTxt2}>{value.header}</h2>
                    <p style={{fontFamily: 'roboto-thin,roboto,sans-serif', fontSize: 14, width: '75%', margin: '0 auto'}}>{value.text}</p>
                </div>

            </div>
        )
    }

    renderValueTexts(value, index) {
        return (
            <div className={styles.textWrapper}>

                <p className={index===0 ? styles.headerTxtWhite : styles.headerTxt}>{value.header}</p>
                <p className={index===0 ? styles.valueTxtWhite : styles.valueTxt}>{value.text}</p>

            </div>
        )
    }

    renderSocialIcons() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <a href="https://fb.me/xchangeve" style={{margin: 15}} target="_blank" rel="noopener noreferrer">
                        <img
                            className={styles.socialIcon}
                            src={require('../assets/latest/facebook.png')} />
                    </a>

                    <a href="https://twitter.com/XchangeEve" style={{margin: 15}} target="_blank" rel="noopener noreferrer">
                        <img
                            className={styles.socialIcon}
                            src={require('../assets/latest/twitter.png')} />
                    </a>

                    <a href="https://t.me/evexchange" style={{margin: 15}} target="_blank" rel="noopener noreferrer">
                        <img
                            className={styles.socialIcon}
                            src={require('../assets/latest/telegram.png')} />
                    </a>
                </div>
            </div>
        )
    }

    getValueRow(index) {
        return (
            <div className={styles.valueWrapper}>
                <ScrollAnimation animateIn="fadeInUp">
                    <div className={styles.valueHolder}>
                        <div className={styles.valueImageWrapper}>
                            <img src={WHY_EVE_TEXTS[index].image} className={styles.whyEveImages}/>
                        </div>
                        <div className={styles.valueTxtWrapper}>
                            <p className={styles.insideValueHeader}>{WHY_EVE_TEXTS[index].header}</p>
                            <p className={styles.insideValueTxt}>{WHY_EVE_TEXTS[index].text}</p>
                        </div>
                    </div>
                </ScrollAnimation>

            </div>
        )
    }

    renderValues() {
        return (
            <div className={styles.valueContainer}>
                <div className={styles.whyEveHeader}>
                    <ScrollAnimation animateIn="fadeInDown" animateOut="fadeOutUp">
                        <h2 className={styles.whyEveHeaderTxt}>Why Choose Eve?</h2>
                    </ScrollAnimation>
                </div>

                <div className={styles.whyEveWrapper}>
                    {this.getValueRow(0)}
                    {this.getValueRow(1)}
                </div>

                <div className={styles.whyEveWrapper}>
                    {this.getValueRow(2)}
                    {this.getValueRow(3)}
                </div>

                <div tabIndex={"0"} ref={this.whyEveRef} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {this.getValueRow(4)}
                </div>


            </div>
        )
    }

    renderAcceptedVendors() {
        return (
            <div className={styles.thirdPartyWrapper}>
                <div className={styles.vendorsHeader}>
                    <ScrollAnimation animateIn="fadeInDown">
                        <h2 className={styles.whyEveHeaderTxt}>Who Accepts Cryptocurrencies?</h2>
                    </ScrollAnimation>

                </div>

                <ScrollAnimation animateIn="flipInX">
                    <img className={styles.thirdPartyImg}
                         src={require('../assets/sav/thirdpartylogos@2x.png')}/>
                </ScrollAnimation>


                <h2 className={styles.manyMoreTxt}>+ Many More!</h2>

            </div>
        )
    }

    renderFooter() {
        return (
            <div className={styles.footerContainer}>

                <ScrollAnimation animateIn="fadeIn">
                    <p style={{color: 'white', fontSize: 20, fontWeight: 'bold', textAlign: 'left'}}>
                        About Us
                    </p>

                    <p className={styles.aboutUsTxt}>
                        Our team members have a strong, diverse and global background and extended domain experience from Harvard, LMU Munich, UCLA, Google, Amazon and Goldman Sachs. Everyone who works at Eve share the vision and values of our community.
                    </p>

                    <p style={{color: 'gray', textAlign: 'left'}}>
                        Disclaimer: Trading in cryptocurrencies are subject to market, technical and legal risks. The prices fluctuate based on local demand and supply.
                    </p>
                    <p
                        onClick={() => this.props.history.push('/privacy')}
                        style={{cursor: 'pointer', color: 'white', textDecoration: 'underline', textAlign: 'left'}}>Privacy Policy</p>

                    <p style={{color: 'gray', textAlign: 'left'}}>© 2020 Digitalhub Softech Pvt Ltd. All rights reserved</p>

                    {this.renderSocialIcons()}
                    <p style={{color: 'white'}}>Contact by email: support@evex.app</p>
                </ScrollAnimation>

            </div>
        )
    }

    renderModalVideoContainer() {
        return (
            <div className={styles.videoModalContainer}>
                {
                    this.state.modalHeader && this.state.modalVideoId ?
                        <div>
                            <p
                                onClick={() => this.setState({modalOpen: false, contactUs: false})}
                                className={styles.modalCloseBtn}>
                                X
                            </p>
                            <h2 className={styles.modalHeader}>{this.state.modalHeader}</h2>
                            <iframe
                                title="Video"
                                className={styles.iframe}
                                    src={"https://www.youtube.com/embed/" + this.state.modalVideoId }>
                            </iframe>
                        </div>
                        :
                        <div className={styles.loader}>
                            <Loader
                                type="Bars"
                                color="#daa520"
                                height={200}
                                width={100}
                            />
                        </div>
                }
            </div>
        )
    }

    renderModalContactForm() {
        return (
            <div className={styles.contactModalContainer}>
                <p
                    onClick={() => this.setState({modalOpen: false, contactUs: false, contactEmailError: false})}
                    className={styles.modalCloseBtn}>
                    X
                </p>
                <h2 className={styles.modalHeader}>{this.state.modalHeader}</h2>
                <p style={{marginTop: 10, color: 'black', textAlign: 'center', fontWeight: 'bold'}}>We will get back to you as soon as possible.</p>


                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20, paddingBottom: 20}}>
                    <input value={this.state.contactName}
                           className={styles.nameInput}
                           onChange={(e) => this.setState({contactName: e.target.value})}
                           placeholder='Enter your name....'
                           type='text'/>

                    <input value={this.state.contactEmail}
                           className={styles.nameInput}
                           onChange={this.onContactEmailChanged.bind(this)}
                           placeholder='Enter your email....'
                           type='text'/>
                    {
                        this.state.contactEmailError &&
                        <p style={{fontSize: 15, color: 'red', marginTop: 4}}>Please enter a valid email</p>
                    }

                    <textarea
                        rows="8"
                        placeholder='Enter your message....'
                        className={styles.messageInput}
                        value={this.state.contactMessage}
                        onChange={(e) => this.setState({contactMessage: e.target.value})} />

                    <button
                        onClick={() => this.onContactSubmitPressed()}
                        type="button"
                        style={{ borderWidth: 1, padding: 10, borderRadius: 5, backgroundColor: THEME_LIGHT, margin: '0 auto', color: 'white', width: 200}}>
                        Submit
                    </button>
                </div>

            </div>
        )
    }

    renderSocialIconsPostSignup() {
        return (
            <div className={styles.socialMediaIconsWrapper}>

                <FacebookShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    quote={SHARE_STRING}>
                    <FacebookIcon size={this.shareIconsSize} round />
                </FacebookShareButton>

                <TwitterShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    title={SHARE_STRING}>
                    <TwitterIcon size={this.shareIconsSize} round />
                </TwitterShareButton>
                <TelegramShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    title={SHARE_STRING}>
                    <TelegramIcon size={this.shareIconsSize} round />
                </TelegramShareButton>
                <RedditShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    title={SHARE_STRING}>
                    <RedditIcon size={this.shareIconsSize} round />
                </RedditShareButton>
                <WhatsappShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    separator={"  "}
                    title={SHARE_STRING}>
                    <WhatsappIcon size={this.shareIconsSize} round />
                </WhatsappShareButton>
                <LinkedinShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    summary={SHARE_TXT}
                    title={SHARE_STRING}>
                    <LinkedinIcon size={this.shareIconsSize} round />
                </LinkedinShareButton>
                <EmailShareButton
                    className={styles.reactSocialIcon}
                    url={this.state.userUrl}
                    body={SHARE_TXT}
                    separator={"  "}
                    subject={SHARE_STRING}>
                    <EmailIcon size={this.shareIconsSize} round />
                </EmailShareButton>

            </div>
        )
    }

    onSurveyAnswerClicked(index) {
        let temp = this.state.surveyAnswers;
        if(temp[index]) {
            temp[index] = false;
        } else {
            temp[index] = true;
        }

        this.setState({
            surveyAnswers: temp
        })
    }

    updateUserPosition() {
        if(!this.state.userDocId) {
            //some error
            console.log('user object not available to update position')
            return;
        }

        let position = this.state.userPosition;

        firestore.collection('WLEmails').doc(this.state.userDocId)
            .update({
                position: position-5,
                surveySubmitted: true
            }).then(() => {
            console.log('updated users position after survey')
        }).catch(err => {
            console.log('error updating  user position after survey', err)
        })
    }


    onSurveyAnswersSubmitted() {
        let surveyAnswers = this.state.surveyAnswers;
        let options = Object.keys(surveyAnswers);

        if(options.length === 0)
            return;

        let flag = false;
        options.forEach(option => {
            if(surveyAnswers[option])
                flag = true
        })

        if(!flag)
            return;

        this.setState({surveyLoading: true})

        firestore.collection('survey').add({
            email: this.state.userEmail,
            answers: surveyAnswers
        }).then(docRef => {
            console.log('survey answers added')
            this.updateUserPosition()
            this.setState({surveyLoading: false, surveySubmitted: true})
        }).catch(err => {
            console.log("some error when submitting survey answers", err)
            this.setState({surveyLoading: false, surveySubmitted: true})
        })

    }

    renderModalPostSignupSurvey() {
        return (
            <div className={styles.contactModalContainer}>
                <p
                    onClick={() => this.setState({modalLoader: false, surveySubmitted: false, modalOpen: false, contactUs: false, contactEmailError: false, userSignedUp: false, showSurvey: false})}
                    className={styles.modalCloseBtn}>
                    X
                </p>

                <img className={styles.waiting} src={require('../assets/sav/moneyLoading.svg')} />
                <p className={styles.modalCon}>Thank you</p>

                {
                    this.state.surveySubmitted ?
                        <p className={styles.modalSurveySugg}>Only <span style={{color: THEME_LIGHT}}>{this.state.userPosition - 6}</span> users ahead of you now!</p>
                        :
                        <p className={styles.modalSurveySugg}>Answer this survey question and skip ahead 5 positions in the priority line</p>
                }


                {
                    this.state.surveySubmitted ?
                        <div>
                            <p className={styles.modalEmail}>This position is reserved for <span style={{color: THEME_LIGHT}}>{this.state.userEmail}</span></p>

                            <p className={styles.modalEmail}>Share your unique link!</p>
                            <p style={{marginTop: 20}} className={styles.urlTxt}>{this.state.userUrl}</p>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {this.renderSocialIconsPostSignup()}
                            </div>


                            <div style={{backgroundImage: `url(${background})`, height: 180, padding: 20, marginTop: 20}}>
                                <p className={styles.modalCon} style={{color: 'white'}}>Follow Us</p>
                                {this.renderSocialIcons()}
                            </div>
                        </div>
                        :
                        <div className={styles.surveyQWrapper}>
                            {
                                this.state.surveyLoading ?
                                    <div style={{height: 400, padding: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Loader
                                            type="Bars"
                                            color="#daa520"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <div style={{margin: '0 auto', paddingBottom: 4, borderBottomWidth: 3, borderBottomColor: THEME_DARK, borderBottomStyle: 'solid', width: '75%'}}>
                                            <p className={styles.modalSurveySugg}>{SURVEY_Q}</p>
                                            <p style={{position: 'relative', bottom: '10%'}}>Select one or more options</p>
                                        </div>

                                        {/*options*/}
                                        <div>
                                            <div
                                                key={'1'}
                                                onClick={() => this.onSurveyAnswerClicked(1)}
                                                className={styles.surveyOptionWrapper}
                                                style={this.state.surveyAnswers[1] ? {backgroundColor: '#cfb46a55'} : {backgroundColor: 'white'}}>
                                                <p>{OPTIONS[0]}</p>
                                            </div>

                                            <div
                                                key={'2'}
                                                onClick={() => this.onSurveyAnswerClicked(2)}
                                                className={styles.surveyOptionWrapper}
                                                style={this.state.surveyAnswers[2] ? {backgroundColor: '#cfb46a55'} : {backgroundColor: 'white'}}>
                                                <p>{OPTIONS[1]}</p>
                                            </div>

                                            <div
                                                key={3}
                                                onClick={() => this.onSurveyAnswerClicked(3)}
                                                className={styles.surveyOptionWrapper}
                                                style={this.state.surveyAnswers[3] ? {backgroundColor: '#cfb46a55'} : {backgroundColor: 'white'}}>
                                                <p>{OPTIONS[2]}</p>
                                            </div>

                                            <div
                                                key={4}
                                                onClick={() => this.onSurveyAnswerClicked(4)}
                                                className={styles.surveyOptionWrapper}
                                                style={this.state.surveyAnswers[4] ? {backgroundColor: '#cfb46a55'} : {backgroundColor: 'white'}}>
                                                <p>{OPTIONS[3]}</p>
                                            </div>

                                            <div
                                                key={'5'}
                                                onClick={() => this.onSurveyAnswerClicked(5)}
                                                className={styles.surveyOptionWrapper}
                                                style={this.state.surveyAnswers[5] ? {backgroundColor: '#cfb46a55'} : {backgroundColor: 'white'}}>
                                                <p>{OPTIONS[4]}</p>
                                            </div>
                                        </div>

                                        <button
                                            onClick={this.onSurveyAnswersSubmitted.bind(this)}
                                            type="button"
                                            style={{padding: 10, borderRadius: 5, backgroundColor: THEME_LIGHT, marginTop: 10, color: 'white'}}>
                                            Submit Answer
                                        </button>
                                    </div>
                            }

                        </div>
                }


            </div>
        )
    }


    onModalPostSignupClosePressed() {
        this.setState({contactUs: false, contactEmailError: false});

        if(this.state.surveyAlreadySubmitted) {
            //dont show survey if already taken
            this.setState({
                modalOpen: false
            })
        } else {
            this.setState({
                showSurvey: true
            })
        }
    }

    onEmailChanged(txt) {
        this.setState({
            luckyUserEmail: txt.target.value,
            luckyUserEmailError: false,
        })
    }

    renderModalPostUserSignup() {
        let {userEmail, userPosition, userReferrals, userUrl, prevUser} = this.state;
        return (
            <div className={styles.contactModalContainer}>
                {
                    this.state.modalLoader ?
                        <div style={{height: 400, padding: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Loader
                                type="Bars"
                                color="#daa520"
                                height={100}
                                width={100}
                            />
                        </div>
                        :
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p
                                onClick={this.onModalPostSignupClosePressed.bind(this)}
                                className={styles.modalCloseBtn}>
                                X
                            </p>

                            {
                                prevUser ?
                                    <p className={styles.modalCon}>Your place is reserved!</p>
                                    :
                                    <p className={styles.modalCon}>Congratulations</p>
                            }


                            <img className={styles.waiting} src={require('../assets/sav/waiting.svg')} />

                            <div className={styles.modalPositionWrapper}>
                                <p className={styles.modalEmail}>This position is reserved for <span style={{color: THEME_LIGHT}}>{userEmail}</span></p>
                                <p className={styles.modalPosition}><span style={{color: THEME_LIGHT}}>{userPosition - 1} </span>users of ahead</p>
                                <p className={styles.modalPosition}>You have referred <span style={{color: THEME_LIGHT}}>{userReferrals}</span> user(s)</p>
                            </div>

                            <p className={styles.modalQuestion}>WANT TO MOVE UP THE WAITING LIST?</p>

                            <p className={styles.modalRefer}>Refer your friends using the unique link. Higher the number of people you refer, faster you will get access</p>

                            {this.renderSocialIconsPostSignup()}

                            <p className={styles.urlTxt}>{userUrl}</p>

                            <p className={styles.modalNoTxt} onClick={this.onModalPostSignupClosePressed.bind(this)}>No Thanks</p>
                        </div>
                }

            </div>
        )
    }

    renderModalQrUserSignup() {
        return (
            <div className={styles.qrModalContainer}>
                <img className={styles.modalLogo} src={require('../assets/sav/logoLatest.png')} />
                <p className={styles.modalQrWelcome}>Are you ready to join millions of users and organizations, already trading and dealing with digital assets?</p>

                <div className={styles.excitingWrapper}>

                    <p className={styles.buyTxt}>
                        Buy, Sell and Trade Cryptocurrencies <span style={{color: THEME_LIGHT}}>FOR FREE</span>
                    </p>
                    <p className={styles.signUpTxt}>
                        Sign up for priority access today!
                    </p>
                    <input value={this.state.luckyUserEmail}
                           className={styles.emailInput}
                           onChange={this.onEmailChanged.bind(this)}
                           placeholder='Enter your email...'
                           onKeyPress={event => {
                               if (event.key === 'Enter') {
                                   this.onSignupPressed()
                               }
                           }} type='text'/>
                    <button
                        onClick={() => this.onSignupPressed()}
                        type="button"
                        className={styles.luckyUserSubmitBtn} >
                        Reserve My Spot
                    </button>

                    <p className={this.state.luckyUserEmailError ? styles.emailError : styles.emailErrorHidden}>Please enter a valid email address</p>
                </div>

                <p className={styles.bonusTxt}>Sign up now for <span style={{fontWeight: 'bold', color: THEME_LIGHT}}>Free Cryptocurrencies</span> and a ton of early user benefits</p>


            </div>
        )
    }

    renderModal() {
        if(this.state.luckyUser) {
            return (
                <div>
                    {this.renderModalQrUserSignup()}
                </div>
            )
        } else if(this.state.showSurvey) {
            return (
                <div>
                    {this.renderModalPostSignupSurvey()}
                </div>
            )
        }



        return (
            <div>
                {
                    this.state.userSignedUp ?
                        this.renderModalPostUserSignup()
                        :

                        this.state.contactUs ?
                            this.renderModalContactForm()
                            :
                            this.renderModalVideoContainer()
                }

            </div>
        )
    }


    render() {
        return (
            <div className={styles.container}>
                {/*header*/}

                <div className={styles.navWrapper} style={this.state.modalOpen ? {zIndex: 0} : {zIndex: 100}}>
                    <div className={styles.logoWrapper} onClick={() => this.props.history.push('/')}>
                        <img className={styles.logoNew} src={require('../assets/sav/logoLatest.png')} />
                    </div>

                    <div className={styles.linksWrapper}>
                        <p className={styles.linkTxt} onClick={() => this.aboutRef.current.focus()}>About</p>
                        <p className={styles.linkTxt} onClick={() => this.whyCryptoRef.current.focus()}>Why Crypto</p>
                        <p className={styles.linkTxt} onClick={() => this.whyEveRef.current.focus()}>Why Eve</p>
                        <p className={styles.linkTxt} onClick={() => this.aiRef.current.focus()}>AI Assistant</p>

                        <button
                            onClick={() => this.setState({modalOpen: true, contactUs: true, modalHeader: 'Contact Us'})}
                            type="button"
                            style={{marginLeft: 20, borderWidth: 1, borderColor: '#9F7F59', padding: 10, borderRadius: 5, backgroundColor: 'white'}}>
                            Contact Us
                        </button>
                    </div>
                </div>

                <WaitingList
                    totalUsersSignedUp={this.state.totalUsersSignedUp}
                    showImage={true}
                    location={this.props.location}
                    showModal={this.showModal}
                    showModalLoader={this.showModalLoader}/>

                <div style={{textAlign: 'center'}}>
                    {/*<h3 className={styles.whatTxt} >What is a Cryptocurrency?</h3>*/}
                    {/*<div*/}
                    {/*    onClick={() => this.showModal('Basics of Money 2.0', 'kubGCSj5y3k')}*/}
                    {/*    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',  cursor: 'pointer'}}>*/}
                    {/*    <img src={require('../assets/latest/satoshi.png')} className={styles.satoshi}/>*/}
                    {/*    <h2 style={{color: '#9F7F59'}}>???</h2>*/}
                    {/*</div>*/}

                </div>

                <div className={styles.aboutWrapper}>

                        <div className={styles.aboutTxtWrapper}>
                            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
                                <h2 tabIndex={"0"} className={styles.aboutTxt} style={{color: THEME_LIGHT}}>Your Crypto Journey Begins Here</h2>
                                <p className={styles.valueTxt}>{ABOUT_TXT1}</p>
                                <p className={styles.valueTxt}>{ABOUT_TXT2}</p>
                                <p className={styles.valueTxt}>{ABOUT_TXT3}</p>
                                <p className={styles.valueTxt}>{ABOUT_TXT4}</p>
                            </ScrollAnimation>
                        </div>




                        <div className={styles.aboutImgWrapper}>
                            <ScrollAnimation animateIn="fadeInRight"  animateOut="fadeOutRight">
                                <img
                                    tabIndex={"0"}
                                    ref={this.aboutRef}
                                    src={"https://storage.googleapis.com/cryptoecons.appspot.com/extraImage.png"}
                                    className={styles.aboutImg}/>
                            </ScrollAnimation>
                        </div>


                </div>

                {/*AI section*/}
                <div className={styles.aiWrapper}>

                        <div className={styles.aboutImgWrapper}>
                            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
                                <img tabIndex={"0"}
                                     ref={this.aiRef}
                                     src={"https://storage.googleapis.com/cryptoecons.appspot.com/MeetEve.png"}
                                     className={styles.aiImg}/>
                            </ScrollAnimation>
                        </div>




                        <div className={styles.aboutTxtWrapper}>
                            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
                                <h2 className={styles.aboutTxt} style={{color: THEME_LIGHT}}>Meet Satoshi</h2>
                                <p className={styles.aiTxt}>{AI_TXT1}</p>
                                <p className={styles.aiTxt}>{AI_TXT2}</p>
                                <p tabIndex={"0"} ref={this.whyCryptoRef} className={styles.aiTxt}>{AI_TXT3}</p>
                            </ScrollAnimation>
                        </div>



                </div>

                <Charts/>

                {
                    this.renderValues()
                }

                {
                    this.renderAcceptedVendors()
                }

                <WaitingList
                    totalUsersSignedUp={this.state.totalUsersSignedUp}
                    showImage={false}
                    location={this.props.location}
                    showModal={this.showModal}
                    atBottom={true}
                    showModalLoader={this.showModalLoader}/>

                {/*coming soon section*/}
                <div style={{backgroundImage: `url(${background})`}}
                    className={styles.comingSoonWrapper}>
                    <div className={styles.comingTxtWrapper}>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p className={styles.comingSoonHeader}>Coming soon: Download our app!</p>
                            <p className={styles.comingSoonTxt}>Come back for <span style={{fontWight: 'bold'}}>FREE CRYPTOCURRENCIES</span> and a ton of early user benefits and discounts</p>
                            <p className={styles.comingSoonTxt}>Follow us on our social media channels and don't miss a chance to win free giveaways</p>
                        </ScrollAnimation>
                    </div>
                    <div className={styles.holdinImgWrapper}>
                        <ScrollAnimation animateIn="fadeInRight">
                            <img src={"https://storage.googleapis.com/cryptoecons.appspot.com/LogOn.png"} className={styles.holdingImg}/>
                        </ScrollAnimation>
                    </div>

                </div>

                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.modalOpen}
                    style={this.state.contactUs || this.state.userSignedUp || this.state.luckyUser? contactModalStyle : videoModalStyle}
                    contentLabel="Modal">
                    {this.renderModal()}
                </Modal>

                {this.renderFooter()}
            </div>
        )
    }

}

export default LandingPage;
