import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Privacy from './components/Privacy'
import LandingPage from "./components/LandingPage";
import WaitingList from './components/WaitingList.js'
import NotFoundPage from './components/NotFoundPage';

class App extends Component {
  render() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/privacy" component={Privacy} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Router>
    );
  }
}

export default App;
