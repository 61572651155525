import React, { Component } from 'react';
import styles from '../styles/WaitingList.module.css'
import Loader from 'react-loader-spinner'
import database from '../services/database'
import axios from "axios";
import background from '../assets/sav/background.svg'
import ScrollAnimation from "react-animate-on-scroll";
import config from "../config";
const queryString = require('query-string-es5');
let firestore = database.firestore();


const THEME_LIGHT = "#c49a2e";


class WaitingList extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            emailError: false,
            emailErrorBottom: false,
            signedUp : false,
            userPosition: null,
            prevUser: false,
            loading: false,
            shared: false,
            alreadyShared: false,
            referred: false,
        }
        this.userId = null;
        this.referredUser = null;
        this.userLocation = null;
        this.shareIconsSize = (window.innerWidth > 900) ? 60 : 40;
        this.getUniqueLink = this.getUniqueLink.bind(this);
        this.onSignupPressed = this.onSignupPressed.bind(this);
        this.reverseGeoCode = this.reverseGeoCode.bind(this);
    }

    componentDidMount() {
        if(!this.props.atBottom) {
            this.getUserCurrentLocation();
        }

        const searchString = this.props.location.search;
        let params = queryString.parse(searchString);
        if(params['referral']) {
            let code = Number(params['referral'])
            if(code) {
                console.log('the referral code is ', code)
                firestore.collection('WLEmails').where("referral","==", code)
                    .get()
                    .then(snapshot => {
                        snapshot.forEach(doc => {
                            this.referredUser = Object.assign({}, doc.data())
                            this.referredUser.id = doc.id;
                        })
                        this.setState({referred: true})
                    }).catch((err) => {
                        console.log('error when getting referred user', err)
                })
            }
        }
    }

    getUserCurrentLocation() {
        let options = {
            enableHighAccuracy: false,
            timeOut: 8000
        }

        navigator.geolocation.getCurrentPosition((location) => {
            let lon = location.coords.longitude;
            let lat = location.coords.latitude;
            this.reverseGeoCode(lat, lon);
        }, () => {
            //user didnt give permission or couldnt get it
            console.log('couldnt gt location')
        }, options)

    }

    reverseGeoCode(lat, lon) {
        //reverse geocoding using google api
        let url = "https://maps.googleapis.com/maps/api/geocode/json?" + "latlng=" + lat + "," + lon + "&key=" + config.googleAPIKey;

        let location = {
            lat: lat,
            lon: lon
        }

        this.userLocation = location;
        // axios.get(url)
        //     .then(function (response) {
        //         // handle success
        //         console.log(response);
        //     })
        //     .catch(function (error) {
        //         //if reverse geocoding fails, store the lat lon directly
        //         console.log('error when reverse geocoding');
        //     })
        //     // .then(function () {
        //     //     // always executed
        //     // });
    }


    getAndUpdateTotalCount() {
        let countRef = firestore.collection("WLEmails").doc("count");

        firestore.runTransaction(function(transaction) {
            return transaction.get(countRef).then(function(countDoc) {
                if (!countDoc.exists) {
                    throw "Document does not exist!";
                }

                let newTotal = countDoc.data().total + 1;
                transaction.update(countRef, { total: newTotal });
                return newTotal;
            });
        }).then(function(newTotal) {
            console.log("added");
        }).catch(function(err) {
            console.error(err);
        });
    }



    getUniqueLink(email) {
        var hash = 0, i, chr;
        if (email.length === 0) return null;
        for (i = 0; i < email.length; i++) {
            chr   = email.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        let url = 'https://evex.app/?referral=' + hash;
        this.setState({
            uniqueUrl: url
        });

        return hash;
    }

    onSignupPressed() {

        let temp = this.state.email;
        let email;

        if(!temp || temp === '' || temp.length === 0 || !this.validateEmail(temp)) {
            if(this.props.atBottom) {
                this.setState({emailErrorBottom: true, loading: false})
            } else {
                this.setState({emailError: true, loading: false})
            }

            return;
        }

        email = temp.toLowerCase();

        this.setState({loading: true});
        this.props.showModalLoader();



        const self = this;
        firestore.collection('WLEmails')
            .where('email', '==', email)
            .get()
            .then(snapshot => {
                if(snapshot.size !== 0) {
                    //user has already signed up. show the waiting list position
                    snapshot.forEach(doc => {
                        const data = doc.data();
                        self.userId = doc.id;
                        this.setState({
                            loading: false,
                        })

                        let surveySubmitted = data.surveySubmitted || false;
                        let referralId = this.getUniqueLink(data.email)
                        let url = 'https://evex.app/?referral=' + referralId;
                        this.props.showModal(doc.id, data.email, data.position, url, true, data.referrals, surveySubmitted);
                        this.setState({loading: false, email: ''})
                        //if for soem reason the first the when the user signed up, there was no refrall id
                        if(!data.referral) {
                            this.updateReferralIdForUser(doc.id, referralId);
                        }
                    })
                } else {
                    let referralId = this.getUniqueLink(email);
                    let url = 'https://evex.app/?referral=' + referralId;
                    let currentUsersList = this.props.totalUsersSignedUp;
                    firestore.collection('WLEmails').add({
                        email: email,
                        position: currentUsersList + 1,
                        ogPosition: currentUsersList + 1,
                        referrals: 0,
                        referral: referralId,
                        location: this.userLocation,
                        createdAt: new Date().toISOString()
                    }).then((docRef) => {
                        this.props.showModal(docRef.id, email,currentUsersList + 1, url, false, 0, false);
                        this.setState({prevUser: false, loading: false, email: ''})
                        self.userId = docRef.id;
                        if(this.state.referred) {
                            //if the user was referred from some one else using their unique link, update the referred user position here
                            this.updateReferredUserPosition();
                        } else {
                            console.log('not referred by anyone')
                        }

                        self.sendEmail(email, currentUsersList, url);//user position -1 needs to be sent to email API because of the copy
                        this.getAndUpdateTotalCount();
                    }).catch((err) => {
                        console.log('something went wrong when signin up.', err)
                        this.setState({email: null, userPosition: null, signedUp: false, loading: false})
                    })
                }
            })
    }

    updateReferralIdForUser(id, referralId) {
        firestore.collection('WLEmails').doc(id).update({
            referral: referralId
        }).then(() => {
            console.log('updated user without the rererral before')
        }).catch((err) => {
            console.log('something happened when updating user referral position', err)
        })
    }

    updateReferredUserPosition() {
        if(!this.referredUser) {
            //some error
            console.log('referred user object not available and hence returning from the funciton')
            return;
        }

        let position = this.referredUser.position;
        let referrals = this.referredUser.referrals || 0;
        firestore.collection('WLEmails').doc(this.referredUser.id)
            .update({
                position: position-1,
                referrals: referrals + 1
            }).then(() => {
                console.log('updated referred users position')
            }).catch(err => {
                console.log('error updating referred user position', err)
            })
    }

    sendEmail(email, position, url) {

        const sendgridData = {
            email: email,
            position: position,
            url: url
        }

        let self = this;
        axios.post(" https://us-central1-cryptoecons.cloudfunctions.net/eve-api/api/email", sendgridData)
            .then(response => {
                if (response && response.status === 200) {
                    console.log('sent email to user through api',)
                } else {
                    self.sendEmailThroughEmailJs(email, position, url)
                    console.log('error when sending email through api because', response.status, response.statusText)
                }
            }).catch((err) => {
            self.sendEmailThroughEmailJs(email, position, url)
            console.log('error when sending email through api', err)
        })



    }

    sendEmailThroughEmailJs(email, position, url) {
        const headers = {
            'Content-Type': 'application/json'
        }

        const emailJsData = {
            service_id: 'default_service',
            template_id: 'template_o0qs8W9b',
            user_id: 'user_Mu9xOl6JnJ4uDsTq20siM',
            template_params: {
                position: position,
                to_email: email,
                url: url,
                reply_to: 'support@evex.app'
            }
        }


        axios.post("https://api.emailjs.com/api/v1.0/email/send", emailJsData, {headers: headers})
            .then(response => {
                if (response && response.status === 200) {
                    console.log('sent email to user through js',)
                } else {
                    console.log('error when sending email', response.status, response.statusText)
                }
            }).catch((err) => {
            console.log('error when sending email', err)
        })
    }

    onEmailChanged(txt) {
        this.setState({
            email: txt.target.value,
            emailError: false,
            emailErrorBottom: false
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onShareClicked() {

        let currentPosition = this.state.userPosition;
        if(this.state.alreadyShared) {
            //the user has already reaped the benefit of sharing once.
            this.setState({
                shared: false
            })
            return;
        }

        firestore.collection('WLEmails').doc(this.userId).update({
            shared: true,
            position: currentPosition - 5
        }).then(() => {
            console.log('uodated user poisition after sharing')
            this.setState({shared: true, userPosition: currentPosition - 5, alreadyShared: true})
        }).catch((err) => {
            console.log('something happened when updating user posiion ', err)
            this.setState({shared: true, userPosition: currentPosition - 5, alreadyShared: true}) //needs to change
        })


    }

    renderEmailSignupForm() {
        return (
            <div className={styles.emailWrapper}>


                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {/*<span onClick={() => this.props.showModal('Basics of Money 2.0', 'kubGCSj5y3k')}*/}
                    {/*    style={{cursor: 'pointer', width: 30, height: 30, backgroundColor: '#99cfe0', alignItems: 'center', justifyContent: 'center', borderRadius: 15, marginLeft: 10}}>*/}
                    {/*    <p style={{fontSize: 20, color: 'white'}}>?</p>*/}
                    {/*</span>*/}
                </div>


                    <p className={styles.buyTxt}>
                        Buy, Sell and Trade Cryptocurrencies <span style={{color: 'black', fontWeight: 'bold'}}>FOR FREE</span>
                    </p>

                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '14px', alignItems: 'center'}}>
                        <ScrollAnimation animateIn="fadeInLeft" delay={1000}>
                            <div className={styles.excitingWrapper}>

                                    <p className={styles.signUpTxt}>
                                        Sign up for priority access today!
                                    </p>
                                    <input value={this.state.email}
                                           className={styles.emailInput}
                                           onChange={this.onEmailChanged.bind(this)}
                                           placeholder='Enter your email...'
                                           onKeyPress={event => {
                                               if (event.key === 'Enter') {
                                                   this.onSignupPressed()
                                               }
                                           }} type='text'/>
                                    <button
                                        onClick={() => this.onSignupPressed()}
                                        type="button"
                                        className={styles.submitBtn} >
                                        Reserve My Spot
                                    </button>

                                    <p className={this.state.emailError ? styles.emailError : styles.emailErrorHidden}>Please enter a valid email address</p>



                            </div>

                            <p className={styles.bonusTxt}>* Come back for <span style={{fontWeight: 'bold', color: 'white'}}>Free Cryptocurrencies</span> and a ton of early user benefits</p>
                            <p className={styles.tncTxt}>(Terms and Conditions apply)</p>
                        </ScrollAnimation>
                    </div>

            </div>
        )
    }

    renderPreSignup() {
        if(this.props.atBottom) {
            return (
                    <div className={styles.signUpFormBottom}>
                        <p className={styles.signUpTxtBottom}>Sign up for priority access today!</p>

                        <input
                            className={this.state.emailErrorBottom ? styles.emailInputErrorBox : styles.emailInputBox}
                            value={this.state.email}
                               onChange={this.onEmailChanged.bind(this)}
                               placeholder='Enter your email...'
                               onKeyPress={event => {
                                   if (event.key === 'Enter') {
                                       this.onSignupPressed()
                                   }
                               }} type='text'/>
                        <button
                            style={{backgroundColor: THEME_LIGHT, height: 40, color: 'white', paddingLeft: 10, paddingRight: 10, borderWidth: 0, borderRadius: 5}}
                            onClick={() => this.onSignupPressed()}
                            type="button">
                            Reserve My Spot
                        </button>

                        <p className={this.state.emailErrorBottom ? styles.emailErrorBottom : styles.emailErrorHiddenBottom}>Please enter a valid email address</p>

                    </div>
            )
        } else {
            return (
                <div className={this.props.showImage ? styles.wrapper : styles.wrapperWithoutPadding} style={this.props.showImage ? {}:{display: 'flex', justifyContent: 'center'}}>
                    {
                        this.state.loading ?
                            <div className={styles.loader}>
                                <Loader
                                    type="Bars"
                                    color="#daa520"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            :
                            this.renderEmailSignupForm()
                    }

                    {
                        this.props.showImage &&
                        <div className={styles.imageWrapper}>
                            <ScrollAnimation animateIn="fadeInRight" delay={1000}>
                                <img
                                    className={styles.img}
                                    src={"https://storage.googleapis.com/cryptoecons.appspot.com/FirstSquareImageCap.png"}/>
                            </ScrollAnimation>
                        </div>
                    }
                </div>
            )
        }
    }


    render() {
        return (
            <div style={this.props.atBottom ? {} : {backgroundImage: `url(${background})`}}
                className={this.props.atBottom ? styles.container : styles.containerWithPaddingBottom}>

                {this.renderPreSignup()}

            </div>
        )
    }
}

export default WaitingList;
