import React, { Component } from 'react';
import axios from 'axios'
import styles from '../styles/Charts.module.css'
import Loader from 'react-loader-spinner'
import Config from "../config";
import background from "../assets/sav/background.svg";

const MINIMUM = 0;
const MAXIMUM = 1000000;

const BTC_URL = 'https://api.coindesk.com/v1/bpi/historical/close.json';
const GOLD_URL = ' https://www.quandl.com/api/v3/datasets/WGC/GOLD_DAILYIND_CONSIDX/data.json';
const DOW_URL = 'https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=VFINX&outputsize=full'

class Charts extends Component {

    constructor() {
        super();
        this.state = {
            amount: 0,
            inputError: false,
            chartData: null,
            loading: false,
            dataLoading: true
        }
        this.btcAndGoldData = {};
        this.gotGoldData = false;
        this.gotBTCData = false;
        this.gotSNPData = false;
        this.checkIfDataAvailable = this.checkIfDataAvailable.bind(this);
    }

    componentDidMount() {
        this.getGoldHistoricalData();
    }

    checkIfDataAvailable() {
        if(!this.gotBTCData || !this.gotSNPData || !this.gotGoldData) {
            return false;
        } else {
            this.setState({
                dataLoading: false
            })
            return true
        }
    }

    getGoldHistoricalData() {
        const url = GOLD_URL + '?start_date=2009-01-01&end_date=2019-11-15&api_key='+ Config.quandlAPIKey

        let config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        }

        axios.get(url,config)
            .then(response => {
                if(response && response.status === 200) {
                    const data = response.data.dataset_data.data;
                    data.forEach(temp => {
                        let jsDate = temp[0];
                        const value = temp[1];
                        // var day = jsDate.getDate();
                        // var monthIndex = jsDate.getMonth();
                        // var year = jsDate.getFullYear();

                        this.btcAndGoldData[jsDate] = {gold: value, date: jsDate}
                    })
                    this.gotGoldData = true;
                    this.checkIfDataAvailable();
                    this.getBTCHistoricalData();
                    this.getSNPHistoricalData();
                } else {
                    console.log('error when getting gold historicial data', response.status, response.statusText)
                }
            }).catch(err => {
            console.log('error when getting gold historicial data', err)
        })
    }

    getSNPHistoricalData() {
        const url = DOW_URL + '&apikey=' + Config.alphaAPIKey

        axios.get(url)
            .then(response => {
                if(response && response.status === 200) {
                    const data = response.data["Time Series (Daily)"];
                    Object.keys(data).forEach(date => {
                        if(this.btcAndGoldData[date]) {
                            //if the gold price for that date is available
                            let dump = this.btcAndGoldData[date];
                            dump['snp'] = Number(data[date]["1. open"]);
                        }
                    })
                    this.gotSNPData = true;
                    this.checkIfDataAvailable();
                } else {
                    console.log('error when getting dow historicial data', response.status, response.statusText)
                }
            }).catch(err => {
            console.log('error when getting dow historicial data', err)
        })
    }

    getBTCHistoricalData() {
        const url = BTC_URL + '?start=2013-01-01&end=2019-11-15';

        axios.get(url)
            .then(response => {
                if(response && response.status === 200) {
                    const data = response.data.bpi;
                    Object.keys(data).forEach(date => {
                        let jsDate = date;
                        const value = data[date];


                        if(this.btcAndGoldData[jsDate]) {
                            //if the gold price for that date is available
                            let dump = this.btcAndGoldData[jsDate];
                            dump['btc'] = value
                        }
                    })
                    this.gotBTCData = true;
                    this.checkIfDataAvailable();
                } else {
                    console.log('error when getting bitcoin historicial data', response.status, response.statusText)
                }
            }).catch(err => {
            console.log('error when getting bitcoin historicial data', err)
        })
    }

    onValueChanged(e) {
        const value = e.target.value;
        if(!isNaN(value)) {
            if(value > MAXIMUM)
                return

            this.setState({
                amount: Number(value),
                inputError: false
            })
        } else {
            this.setState({
                inputError: true
            })
        }
    }

    onSliderValueChange(event) {
        let value = event.target.value;
        this.setState({
            amount: value
        })
    }

    onSubmitClicked() {
        const amount = this.state.amount;
        if(amount === 0)
            return

        if(!this.checkIfDataAvailable()) {
            return;
        }

        this.setState({loading: true})

        let btcValueToday = this.btcAndGoldData["2019-11-07"].btc;
        let btcValueOneYear = this.btcAndGoldData["2018-12-14"].btc;
        let btcValueFiveYear = this.btcAndGoldData["2014-12-30"].btc;
        let btcValueTenYear = 1;
        let noOfCoins1 = (amount/btcValueOneYear)
        let noOfCoins5 = (amount/btcValueFiveYear)
        let noOfCoins10 = (amount/btcValueTenYear)

        let goldValueToday = this.btcAndGoldData["2019-11-07"].gold;
        let goldValueOneYear = this.btcAndGoldData["2018-12-14"].gold;
        let goldValueFiveYear = this.btcAndGoldData["2014-12-30"].gold;
        let goldValueTenYear = this.btcAndGoldData["2009-12-16"].gold;
        let noOfGold1 = (amount/goldValueOneYear)
        let noOfGold5 = (amount/goldValueFiveYear)
        let noOfGold10 = (amount/goldValueTenYear)

        let snpValueToday = this.btcAndGoldData["2019-11-07"].snp;
        let snpValueOneYear = this.btcAndGoldData["2018-12-14"].snp;
        let snpValueFiveYear = this.btcAndGoldData["2014-12-30"].snp;
        let snpValueTenYear = this.btcAndGoldData["2009-12-16"].snp;
        let noOfSnp1 = (amount/snpValueOneYear)
        let noOfSnp5 = (amount/snpValueFiveYear)
        let noOfSnp10 = (amount/snpValueTenYear)

        let temp = {
            btc1: Math.ceil(Number(((noOfCoins1 * btcValueToday) - amount).toFixed(5))),
            btc5: Math.ceil(Number(((noOfCoins5 * btcValueToday) - amount).toFixed(5))),
            btc10: Math.ceil(Number(((noOfCoins10 * btcValueToday) - amount).toFixed(5))),
            gold1: Math.ceil(Number(((noOfGold1 * goldValueToday) - amount).toFixed(5))),
            gold5: Math.ceil(Number(((noOfGold5 * goldValueToday) - amount).toFixed(5))),
            gold10: Math.ceil(Number(((noOfGold10 * goldValueToday) - amount).toFixed(5))),
            snp1: Math.ceil(Number(((noOfSnp1 * snpValueToday) - amount).toFixed(5))),
            snp5: Math.ceil(Number(((noOfSnp5 * snpValueToday) - amount).toFixed(5))),
            snp10: Math.ceil(Number(((noOfSnp10 * snpValueToday) - amount).toFixed(5))),
        }

        this.setState({loading: false, chartData: temp})

        console.log('btc value today', this.btcAndGoldData["2019-11-07"])
        console.log('btc value 1 year', this.btcAndGoldData["2018-12-14"].gold)
        console.log('btc value 5 year', this.btcAndGoldData["2014-12-30"].gold)
        console.log('btc value 10 year', this.btcAndGoldData["2009-12-16"].gold)

        // Object.keys(this.btcAndGoldData).forEach((each) => {
        //     let btcData = this.btcAndGoldData[each].btc;
        //     let goldData = this.btcAndGoldData[each].gold;
        //     let snpData = this.btcAndGoldData[each].snp;
        //     let jsDate = new Date(each)
        //     var day = jsDate.getDate();
        //     var monthIndex = jsDate.getMonth();
        //     var year = jsDate.getFullYear();
        //
        //     if(year === 2019 && monthIndex === 9)
        //         console.log('data', this.btcAndGoldData[each])
        //
        //     chartData.push({
        //         jsDate: jsDate,
        //         BTC: btcData,
        //         gold: goldData,
        //         snp: snpData,
        //         BTCProfit: Math.ceil(Number(((noOfCoins * btcData) - amount).toFixed(5))),
        //         goldProfit: Math.ceil(Number(((amountOfGold * goldData) - amount).toFixed(5))),
        //         snpProfit: Math.ceil(Number(((amountOfDow * snpData) - amount).toFixed(5)))
        //     })
        // })
    }

    // nikkei - japan
    // dax - german
    // ftse - london
    // nasdaq - us

    getTableData() {

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        let toReturn = [];
        let chartData = this.state.chartData;

        toReturn.push(
            <tr>
                <td>Dec 2018(1 year ago)</td>
                <td>{formatter.format(chartData['btc1'])}</td>
                <td>{formatter.format(chartData['gold1'])}</td>
                <td>{formatter.format(chartData['snp1'])}</td>
            </tr>
        )

        toReturn.push(
            <tr>
                <td>Dec 2014(5 years ago)</td>
                <td>{formatter.format(chartData['btc5'])}</td>
                <td>{formatter.format(chartData['gold5'])}</td>
                <td>{formatter.format(chartData['snp5'])}</td>
            </tr>
        )

        toReturn.push(
            <tr>
                <td>Dec 2009(10 years ago)</td>
                <td>{formatter.format(chartData['btc10'])}</td>
                <td>{formatter.format(chartData['gold10'])}</td>
                <td>{formatter.format(chartData['snp10'])}</td>
            </tr>
        )

        // chartData.forEach(data => {
        //     let jsDate = data.jsDate;
        //     let day = jsDate.getDate();
        //     let monthIndex = jsDate.getMonth();
        //     let year = jsDate.getFullYear();
        //     if((monthIndex === 11 && day === 15 && year !== 2019 && year !== 2018 && year !== 2013)
        //         || (monthIndex === 9 && day === 15 && year === 2019)
        //         || (monthIndex === 11 && day === 21 && year === 2018)
        //         || (monthIndex === 11 && day === 4 && year === 2013)) {
        //
        //         toReturn.push(
        //             <tr>
        //                 <td>{MONTHS[monthIndex]} {year} After {year - 2012} year(s)</td>
        //                 <td>{formatter.format(data.BTCProfit)}</td>
        //                 <td>{formatter.format(data.goldProfit)}</td>
        //                 <td>{formatter.format(data.snpProfit)}</td>
        //             </tr>
        //         )
        //     }
        //
        // })
        return toReturn;
    }

    renderTable() {
        if(!this.state.chartData )
            return

        let toReturn = [];
        toReturn.push(

            <table className={styles.table}>
                <tbody>
                <tr>
                    <th>Invested In</th>
                    <th>Bitcoin Profit</th>
                    <th>Gold Profit</th>
                    <th>Vanguard S&P 500 Profit</th>
                </tr>
                {this.getTableData()}
                </tbody>
            </table>
        )

        return toReturn;
    }

    renderEverything() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.sliderWrapper}>

                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
                        <h1 className={styles.dollar}>$ </h1>
                        <input value={this.state.amount}
                               className={styles.txtInput}
                               onChange={this.onValueChanged.bind(this)}
                               placeholder='Enter the amount'
                               type='text'/>
                    </div>

                    <div className={styles.moneyWrapper}>
                        <h4 style={{color: 'white'}}>$ 0</h4>
                        <h4 style={{color: 'white'}}>$ 1,000,000</h4>
                    </div>
                    <input
                        className={styles.slider}
                        onChange={this.onSliderValueChange.bind(this)}
                        style={{width: '80%', outline: 'none'}}
                        type="range"
                        min={MINIMUM}
                        max={MAXIMUM}
                        value={this.state.amount}/>

                    <button
                        onClick={this.onSubmitClicked.bind(this)}
                        type="button"
                        className={styles.submitBtn}>
                        {
                            this.state.dataLoading ?
                                <Loader
                                    type="Puff"
                                    color="white"
                                    height={40}
                                    width={40}
                                />
                                :
                                <p className={styles.submitTxt}>Submit</p>
                        }

                    </button>

                </div>

                <div className={styles.tableWrapper}>
                    {this.renderTable()}
                </div>
            </div>
        )
    }

    render() {
        return(
            <div style={{backgroundImage: `url(${background})`}}
                className={styles.container}>
                    <p className={styles.headerTxt}>An Investment Comparison Time Machine</p>
                    <p className={styles.expTxt}>Enter an amount or slide the bar to select an amount and submit. The results compare returns from investing in Bitcoin(the very first cryptocurrency) vs Gold vs Vanguard S&P 500 index fund</p>


                {this.state.loading ?
                    <Loader
                        type="Bars"
                        color="#daa520"
                        height={100}
                        width={100}
                    />
                    :
                    this.renderEverything()
                }
            </div>
        )
    }

}

export default Charts;
