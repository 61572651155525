import React, { Component } from 'react';
import styles from "../styles/LandingPage.module.css";


class NotFoundPage extends Component {

    render() {
        return (
            <div style={{paddingLeft: 100, paddingRight: 100, alignItems: 'center', color: 'white', backgroundColor: 'white'}}>
                {/*navigation bar*/}
                <div className={styles.navWrapper}>
                    <div className={styles.logoWrapper2} onClick={() => this.props.history.push('/')}>
                        <img style={{margin: '0 auto', height: 60, width: 270}} src={require('../assets/sav/LogoNew.png')} />
                    </div>
                </div>

                {/*just a dummy container to add some space*/}
                <div style={{height: 200, width: '100%'}}>
                </div>

                <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 40}}>404</p>
                <p style={{textAlign: 'center', fontSize: 28}}>Oh no! Page not found.</p>
                <p  style={{textAlign: 'center', textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}
                    onClick={() => this.props.history.push('/')}>
                    Take me home
                </p>

            </div>
        )
    }

}

export default NotFoundPage;
